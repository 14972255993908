<template>
	<el-dialog
		v-model="isShowDialog"
		width="700px"
		class="model-dialog-container"
		:title="dialogTitleComp"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<p>{{ addressText }}</p>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, defineEmits, inject, reactive, getCurrentInstance, ref, computed } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()

const $message = inject('$message')
const $messageBox = inject('$messageBox')

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const formInitValue = {
	name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	productiveYear: '',
	status: '',
	indicativePrice: undefined,
}
const formData = reactive({})
const formRule = rules
const statusOptions = [
	{ name: '下架', code: 10 },
	{ name: '上架', code: 20 },
]

const addressText = ref('获取地址中')
const brandOptions = ref([])
const seriesOptions = ref([])
const emit = defineEmits(['refresh'])

const formType = ref('create')
let waitUpdateId = ''

const dialogTitleComp = computed(() => {
	switch (formType.value) {
		case 'create':
			return '查看地名'
		default:
			return ''
	}
})

function getAddressDetail(id) {
	Request({
		url: `/suf4-risk-service/vehicleGpsInfo/getAddress/${id}`,
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('getAddressDetail成功 ==>', res.data)
				addressText.value = res.data
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			console.log('getAddressDetail失败 ==>', error)
			return false
		})
}

async function update(item) {
	waitUpdateId = item.id
	console.log('即将进行编辑 ==>', item)
	getAddressDetail(item.carNumber)
	Object.assign(formData, item)
	isShowDialog.value = true
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = formData
		if (formType.value === 'create') {
			delete params.id
			requestUrl = `suf4-user-service/sysCarModel/create`
		} else {
			requestUrl = `suf4-user-service/sysCarModel/update`
			params.id = waitUpdateId
		}
		console.log('表单内容 ==>', params)
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: {
				...params,
				outerColors: params.outerColors.join(','),
			},
		})
		if (updateRes.code === 200) {
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	Object.assign(formData, formInitValue)
	isShowDialog.value = false
}

defineExpose({ update })
</script>

<style lang="less">
.number-input-left {
	.el-input__inner {
		text-align: left;
	}
}
</style>
<style lang="less" scoped>
.unit-box {
	width: 40px;
	height: 40px;
	.flexible(row, center, center);
}

.short-box {
	width: 200px;
}

.brand-button {
	margin-left: 12px;
}
.model-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
	}
}
</style>
