import numeral from 'numeral'

export const columns = [
	{
		prop: 'modelName',
		label: '车型名称',
		width: 300,
	},
	{
		prop: 'carNo',
		label: '自编号',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
	},
	{
		prop: 'color',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机名称',
	},
	{
		prop: 'driverPhone',
		label: '司机手机号',
		/* formatter: function (row, column, cellValue, index) {
			return cellValue === 20 ? '上架' : '下架'
		}, */
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		/* formatter: function (row, column, cellValue) {
			return numeral(cellValue).divide(10000).format('0,0.00')
		}, */
	},
	/* {
		prop: 'lat',
		label: '当前经纬度',
		formatter: function (row) {
			return `经度:${row.lon} 纬度:${row.lat}`
		},
	}, */
	{
		prop: 'speed',
		label: '当前时速(km/h)',
	},
	{
		prop: 'mileage',
		label: '公里数（KM）',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'createTime',
		label: '创建时间',
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'driverSupportId',
			label: '司服伙伴',
			type: 'select',
			placeholder: '请选择司服伙伴',
		},
		{
			attr: 'companyId',
			label: '所属公司',
			type: 'select',
			placeholder: '请选择所属公司',
		},
	],
}
/*
name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	status: '',
	indicativePrice: '',
*/

export const rules = {}
